import React, { Component } from 'react';

class Contact extends Component {
  render() {
    return (
      <div className='centered'>
        Contact.
      </div>
    )
  }
}

export default Contact;
