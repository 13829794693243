import React from 'react';
// import { Navbar } from '..';

import './assets/home.css';

const Home = () => {
  return (
    <div>
      {/* <Navbar /> */}
      <div className="homeColumn">
        <div className="homeSection">
          <p>I'm currently available for contract or permanent work, remote or on-site</p>
          <ul>
            <li><a href="https://torquil-fileshare.s3.eu-west-2.amazonaws.com/CV.pdf">Grab a copy of my CV</a></li>
            <li>or</li>
            <li>contact me at <a href="mailto:engineer@torquilmacleod.com">engineer@torquilmacleod.com</a></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Home;
