import React, { Component } from "react";
import './assets/header.css';

class Header extends Component {
  render() {
    return (
      <div className="header">
        <h1>Torquil MacLeod</h1>
        <h2>Certified AWS Engineer</h2>
      </div>
    )
  }
}

export default Header;
